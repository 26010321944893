.timeline-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    position: relative;
  }
  
  .timeline-step {
    text-align: center;
    position: relative;
    width: 100%;
  }
  
  .timeline-dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 auto;
    background-color: gray;
  }
  
  .timeline-dot.completed {
    background-color: green;
  }
  
  .timeline-dot.current {
    background-color: orange;
  }
  
  .timeline-line {
    position: absolute;
    top: 10px;
    left: 50%;
    width: 100%;  /* Spans the entire space between steps */
    height: 2px;
    background-color: gray;
    z-index: -1;
  }

  .timeline-line.upcoming {
    position: absolute;
    top: 10px;
    left: 0%;
    right: 100%;
    width: 100%;  /* Spans the entire space between steps */
    height: 2px;
    background-color: gray;
    z-index: -1;
  }
  
  
  .timeline-line.completed {
    background-color: green;
  }
  
  /* First step line starts from the dot */
  .timeline-step:first-child .timeline-line {
    left: 50%;  /* Ensures the line starts from the first dot */
  }
  
  /* Last step line ends before the last dot */
  .timeline-step:last-child .timeline-line {
    width: 50%;  /* Adjust the width so the line ends at the middle of the last dot */
  }
  
  /* Position the labels below the timeline */
  .timeline-label {
    margin-top: 10px;
  }
  
  .event-date {
    font-size: 12px;
    color: gray;
  }
  