.date-range-selector {
    display: flex;
    justify-content: center;
    align-items: center;
}

.date-input {
    max-width: 150px;
}

.custom-table {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
}

.table-primary {
    background-color: #007bff;
    color: white;
}

.text-center {
    text-align: center;
}
