/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

.landing-page {
  text-align: center;
  color: #333;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #333;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  color: white;
}

.navbar-logo a {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.navbar-links {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.navbar-login .btn-secondary {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
}

.navbar-login .btn-secondary:hover {
  background-color: #0056b3;
}

/* Hero Section */
.hero {
  background: url('/public/hero.jpeg') center/cover no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: green;
}

.hero-content {
  max-width: 600px;
  margin-top: 50px;  /* Offset for the fixed navbar */
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.btn-primary {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.features, .about, .testimonials {
  padding: 50px 20px;
}

.features-grid, .testimonials-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-item, .testimonial-item {
  max-width: 300px;
  padding: 20px;
}

/* Contact Section */
.contact {
  background-color: #f9f9f9;
  padding: 50px 20px;
}

.contact h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.contact-form input, .contact-form textarea {
  padding: 10px;
  margin-bottom: 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Footer */
footer {
  padding: 20px;
  background-color: #333;
  color: white;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.social-links a:hover {
  text-decoration: underline;
}
